@import "./mixins";

.visible-list {
  visibility: visible;
  display: flex !important; }

.App {
    .main-card {
        @include flexbox(center, flex-start, row, nowrap);
        padding: 0 0 0 1em;
        margin-top: 2em;
        height: calc(100vh - 17.3em);
        .alerts-section {
            @include flexbox(center, center, column, nowrap);
            margin-right: 1em;
            margin: 1em 1em 0 0;
            .workshift {
                @include flexbox(center, center, column, nowrap);
                background: linear-gradient(to right top, #00c6ff 0%, #0072ff 100%);
                text-transform: uppercase;
                padding: .3em .5em;
                font-size: .8em;
                font-weight: 500;
                letter-spacing: .06em;
                border-radius: .2em;
                margin: .7em 0 1.5em;
                &__header {
                    @include flexbox(space-between, center, row, nowrap);
                    span {
                        margin-right: .45em; } }
                &__body {
                    @include flexbox(center, center, column, nowrap);
                    font-size: 4em;
                    font-weight: bold; } } }

        .map-wrapper {
            position: relative;
            @include square(100%);
            border-radius: .4em;
            overflow: hidden;
            @include flexbox(center, center, column, nowrap);
            .map-container {
                @include position(absolute, 0, 0, 0, 0); }
            .map-info-panel {
                visibility: hidden;
                z-index: 2;
                @include position(absolute, 0, 0, 0, 0);
                padding: 1em 1em 2.3em 1em;
                @include flexbox(flex-start, flex-end, row, nowrap);
                .map-filters-controller {
                    visibility: visible;
                    @include position(absolute, 1em, 1em, unset, unset);
                    .filters-wrapper {
                        width: 9em;
                        @include flexbox(flex-start, flex-start, column, nowrap);
                        .filter-trigger {
                            z-index: 1;
                            cursor: pointer;
                            @include flexbox(space-between, center, row, nowrap);
                            background-color: $main-text-color;
                            border-radius: .4em;
                            padding: .5em .7em;
                            position: relative;
                            width: calc(100% - 1.4em);
                            color: $main-bg-color;
                            font-weight: 500;
                            > div {
                                margin-right: .2em;
                                background: lighten($main-bg-color, 30%); } }
                        .filters-list {
                            user-select: none;
                            display: none;
                            position: relative;
                            top: -0.5em;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            background-color: $main-text-color;
                            border-radius: 0 0 .4em .4em;
                            padding: .3em .7em 0 .7em;
                            width: calc(100% - 1.4em);
                            color: $main-bg-color;
                            .filter {
                                @include flexbox(space-between, center, row, nowrap);
                                position: relative;
                                width: 97%;
                                span {
                                    font-weight: bold;
                                    font-size: .9em; } } } } }

                .rivers-alerts {
                    visibility: visible;
                    @include flexbox(flex-start, flex-end, row, nowrap); } } } }
    .meteo-widget {
        position: relative;
        width: 100%; } }
