@import ".././mixins";

table {
  th, td {
    font-weight: 500;
    letter-spacing: .04em;
    font-size: .9em; }
  .point {
    height: 1em;
    width: 1em;
    border-radius: 50%;
    background-color: inherit; }
  .alert-green {
    background-color: $green; }
  .alert-yellow {
    background-color: $yellow; }
  .alert-orange {
    background-color: $orange; }
  .alert-red {
    background-color: $red; } }

// Alerts chips (homepage section)

.chips-container {
  @include flexbox(flex-start, center, column, wrap);
  margin: .5em 0;
  .chip:not(:first-child) {
    margin-top: .8em; }
  .chip:not(:last-child) {
    margin-bottom: .8em; }

  .alert-green {
    border-color: $green;
    background-color: $main-bg-color; }
  .alert-yellow {
    border-color: $yellow;
    background-color: $main-bg-color; }
  .alert-orange {
    border-color: $orange;
    background-color: rgba($orange, 0.4); }
  .alert-red {
    border-color: $red;
    background-color: rgba($red, 0.4); }
  .chip {
    position: relative;
    @include flexbox(center, center, row, nowrap);
    padding: .8em;
    margin: 0 .4em;
    border-radius: .4em;
    color: darken($main-text-color, 10%);
    border-width: .34em;
    border-style: solid;
    .alert-icon-wrapper {
      @include flexbox(center, center, row, nowrap);
      @include square($alert-icon-size);
      overflow: hidden; }
    .tooltip {
      z-index: 4;
      pointer-events: none; } } }
