@import "../mixins";

.horizontal-bar-resized {
    padding-left: 8em !important; }
.horizontal-bar {
    @include position(fixed, 0, 0, unset, 0);
    z-index: 3;
    height: 4.45em;
    padding: 0 2em 0 20em;
    font-size: .9em;
    background-color: darken($secondary-bg-color, 14%);
    color: $main-text-color;
    border-bottom: .07em solid rgba($main-text-color, .14);
    transition: padding-left .3s ease-out;
    @include flexbox(space-between, center, row, nowrap);
    .time-section {
        @include flexbox(flex-start, center, row, nowrap);
        .date-value, .time-value {
            font-size: 1.15em;
            letter-spacing: .04em;
            font-weight: 400; }
        .date-value {
            margin-right: .8em; }
        .time-container {
            @include flexbox(flex-start, center, row, nowrap);
            width: 4em; } }

    .right-buttons-section {
        @include flexbox(center, center, row, nowrap);
        .user-name {
            font-weight: bold;
            letter-spacing: .03em;
            font-size: .9em; }
        .user-image {
            @include flexbox(center, center, column, nowrap);
            overflow: hidden;
            @include square($nav-user-image-size);
            border-radius: 50%;
            margin-left: 1.3em;
            background-color: rgba($main-text-color, .14);
            .user-sprite {
                opacity: 0.85; } }
        .logout-btn {
            cursor: pointer;
            position: relative;
            @include flexbox(center, center, row, nowrap);
            font-weight: 500;
            letter-spacing: .04em;
            .logout-ico-btn {
                @include flexbox(center, center, row, nowrap);
                padding: .5em;
                margin-left: .6em;
                border-radius: 50%;
                background-color: transparent;
                transition: transform .3s ease-out, background-color .2s ease-out;
                &:hover {
                    background-color: rgba($main-text-color, .14); } } } } }

.closed-navbar {
    width: 5em !important;
    .app-logo {
        .name-logo {
            opacity: 0 !important;
            transition: opacity .1s linear !important; }
        .tablet-logo {
            background-image: url(../../images/logo.svg);
            height: .89em;
            width: 1em;
            z-index: 2;
            opacity: 1 !important;
            transition: opacity .2s linear .1s !important; } }
    ul {
        li {
            a {
                span {
                    opacity: 0 !important;
                    transition: opacity .1s linear !important; } } } }
    .bottom-section {
        .credits {
            opacity: 0 !important;
            transition: opacity .1s linear !important; }
        .bottom-button {
            transform: rotateZ(180deg); } } }
.navbar {
    @include position(fixed, 0, unset, 0, 0);
    z-index: 3;
    width: 16em;
    background-color: darken($secondary-bg-color, 14%);
    border-right: .07em solid rgba($main-text-color, .14);
    @include flexbox(flex-start, flex-start, column, nowrap);
    flex-shrink: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: width .3s ease-out;
    .app-logo {
        @include flexbox(center, center, column, nowrap);
        flex-shrink: 0;
        font-size: 1.6em;
        height: 64px;
        position: relative;
        width: 100%;
        font-weight: 200;
        color: $main-text-color;
        border-bottom: .05em solid rgba($main-text-color, .14);
        * {
            position: absolute; }
        .name-logo {
            height: .89em;
            width: 4em;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(../../images/title.svg);
            opacity: 1;
            transition: opacity .2s ease .1s; }
        .tablet-logo {
            position: {}
            opacity: 0;
            transition: opacity .1s ease; } }
    ul {
        @include flexbox(flex-start, flex-start, column, nowrap);
        list-style-type: none;
        position: relative;
        width: 100%;
        padding-left: 0;
        margin: 0;
        flex-shrink: 0;
        margin-bottom: 4em;
        li {
            position: relative;
            width: 100%;
            @include flexbox(flex-start, center, row, nowrap);
            background-color: transparent;
            transition: background-color .2s ease;
            & > a {
                position: relative;
                width: 100%;
                color: $main-text-color;
                cursor: pointer;
                padding: 1em;
                font-size: .9em;
                font-weight: 400;
                letter-spacing: .04em;
                @include flexbox(flex-start, center, row, nowrap);
                &.active {
                    pointer-events: none;
                    color: $main-text-color;
                    font-weight: 500;
                    background-color: rgba($main-text-color, .07);
                    .nav-ico {
                        background: linear-gradient(to right top, #00c6ff 0%, #0072ff 100%); } }
                span {
                    @include position(absolute, unset, unset, unset, 5em);
                    opacity: 1;
                    transition: opacity .2s ease .3s; }
                .nav-ico {
                    margin: 0 1em; } }
            &:hover {
                background-color: rgba($main-text-color, .07); } } }

    .settings-section {
        @include position(absolute, unset, unset, 0, unset);
        width: 100%; }
    .bottom-section {
        @include position(absolute, unset, 0, 0, 0);
        height: 4em;
        overflow: hidden;
        @include flexbox(flex-start, center, row, nowrap);
        .credits {
            position: absolute;
            width: 5em;
            color: $main-text-color;
            font-size: .8em;
            font-weight: 500;
            letter-spacing: .04em;
            padding-left: 2em;
            overflow: hidden;
            opacity: 1;
            transition: opacity .2s ease .3s; }
        .bottom-button {
            @include position(absolute, unset, 1.4em, unset, unset);
            cursor: pointer;
            padding: .5em .6em .5em .4em;
            color: $main-text-color;
            border-radius: 50%;
            @include flexbox(center, center, row, nowrap);
            background-color: transparent;
            transition: transform .3s ease-out, background-color .2s ease-out;
            &:hover {
                background-color: rgba($main-text-color, .07); } } } }
