@import "mixins";

* {
  outline: none; }

a {
  text-decoration: none;
  color: $main-accent-color; }

button {
  cursor: pointer;
  appearance: none;
  border: none;
  background: inherit;
  padding: .5em;
  border-radius: .4em;
  color: inherit;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  letter-spacing: .04em;
  font-size: 12px; }

.primary-button {
  color: darken($main-text-color, 10%);
  background: darken($main-accent-color, 10%);
  transition: background .2s ease;
  &:hover {
    background: darken($main-accent-color, 20%); } }

.important-button {
  color: lighten($red, 5%);
  background: transparent;
  border: .14em solid darken($red, 10%); }

.section {
  position: relative; }

.section-title {
  @include position(absolute, -2.2em, unset, unset, 0);
  margin: 0;
  font-size: .95em;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .04em;
  color: darken($main-text-color, 20%); }

table, .card {
  padding: 1em;
  overflow: hidden;
  background-color: darken($secondary-bg-color, 9%);
  border-radius: .4em;
  color: $main-text-color;
  border: .07em solid rgba($main-text-color, .1); }

.card {
  @include flexbox(center, center, column, nowrap);
  .gradient-text {
    font-size: .8em;
    font-weight: bold;
    letter-spacing: .04em;
    text-transform: uppercase;
    background: linear-gradient(to right top, #00c6ff 0%, #0072ff 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent; }
  .main-text {
    font-size: 2.1em;
    font-weight: 200; }
  .small-info-container {
    @include flexbox(center, center, row, wrap);
    padding: 1em 0; }
  .small-info {
    @include flexbox(center, center, row, nowrap);
    font-size: .8em;
    font-weight: 200;
    letter-spacing: .04em;
    .ico {
      margin-right: .8em; } } }

table {
  padding: 1em 0;
  width: max-content;
  text-align: left;
  margin: 0 0 3em;
  overflow: hidden;
  flex: 1 0; }

td, th {
  text-align: left;
  padding: 1em; }

th {
  color: rgba($main-text-color, .55); }

.resized-content {
  padding-left: 7em !important; }

.App {
  position: relative;
  font-size: 16px;
  min-height: 100%;
  left: 0;
  right: 0;
  padding: 6em 2em 2em 18em;
  transition: padding-left .3s ease;
  @include breakpoint("<tablet") {
    padding: 6em 2em 2em 7em; } }

.center-vertical-tooltip-trigger {
  @include flexbox(center, center, column, nowrap); }

.tooltip-trigger:hover {
  .tooltip {
    opacity: 1;
    transform: scale(1); }
  .bottom-tooltip {
    top: 3em; }
  .right-tooltip {
    left: 6em; }
  .top-tooltip {
    bottom: 4em; } }

.tooltip {
  opacity: 0;
  position: absolute;
  width: max-content;
  color: $main-text-color;
  transform: scale(0);
  transition: all .2s ease; }

.small-tooltip {
  border-radius: .2em;
  padding: .2em .5em; }

.normal-tooltip {
  border-radius: .4em;
  padding: .4em .55em; }

.accent-tooltip {
  background-color: darken($main-accent-color, 5%); }

.dark-tooltip {
  background-color: $main-bg-color; }

.bottom-tooltip {
  top: 0; }

.right-tooltip {
  left: 0; }

.top-tooltip {
  bottom: 0; }


/* Checkbox style */

.md-checkbox {
  position: relative;
  margin: 1em 0;
  text-align: left; }

.md-checkbox label {
  cursor: pointer;
  display: inline;
  line-height: 1.25em;
  vertical-align: top;
  clear: both;
  padding-left: 1px; }

.md-checkbox label:before, .md-checkbox label:after {
  content: "";
  @include position(absolute, 0, unset, unset, 0); }

.md-checkbox label:before {
  @include square(1.2em);
  background: transparent;
  border: 2px solid $main-accent-color;
  border-radius: 0.125em;
  cursor: pointer;
  transition: background .25s linear; }

.md-checkbox input[type="checkbox"] {
  outline: 0;
  visibility: hidden;
  width: 1.25em;
  margin: 0;
  display: block;
  float: left;
  font-size: inherit; }

.md-checkbox input[type="checkbox"]:checked + label:before {
  background: $main-accent-color; }

.md-checkbox input[type="checkbox"]:checked + label:after {
  transform: translate(0.25em, 0.3365384615em) rotate(-45deg);
  width: 0.75em;
  height: 0.375em;
  border: 0.125em solid #fff;
  border-top-style: none;
  border-right-style: none; }

/* Navbar icons and sprites */

.home-icon {
  @include icon($nav-icon-size, 0); }

.cloud-icon {
  @include icon($nav-icon-size, ($nav-icon-size * 1)-1); }

.river-icon {
  @include icon($nav-icon-size, ($nav-icon-size * 2)-1); }

.alert-icon {
  @include icon($nav-icon-size, ($nav-icon-size * 3)-1); }

.interventions-icon {
  @include icon($nav-icon-size, ($nav-icon-size * 4)-1); }

.team-icon {
  @include icon($nav-icon-size, ($nav-icon-size * 9)-1); }

.settings-icon {
  @include icon($nav-icon-size, ($nav-icon-size * 7)-1); }

.logout-icon {
  @include icon($nav-icon-button-size, ($nav-icon-button-size * 5)-1); }

.arrow-icon {
  @include icon($nav-icon-button-size, ($nav-icon-button-size * 6)-1); }

.user-sprite {
  @include sprite($nav-user-image-size, ($nav-user-image-size * 26)-2);
  background-color: $main-text-color; }

/* Map icons and sprites */

.triangle-icon {
  @include icon($mini-icon-size, ($mini-icon-size * 8)-1);
  transition: transform .25s ease-out; }

.triangle-down {
  transform: rotateZ(180deg); }

.firestation-sprite {
  @include sprite($map-sprite-size, ($map-sprite-size * 27)-1); }

.river-station-sprite {
  @include sprite($map-sprite-size, ($map-sprite-size * 28)-1); }

.place-icon {
  @include icon($nav-icon-button-size, ($nav-icon-button-size * 11)-1); }

.place-sprite {
  @include icon($map-sprite-size, ($map-sprite-size * 11)-1); }

/* Alerts icons */

.idraulic-alert {
  @include icon($alert-icon-size, ($alert-icon-size * 30)+2); }

.idrogeo-alert {
  @include icon($alert-icon-size, ($alert-icon-size * 2)-1); }

.bad-weather-alert {
  @include icon($alert-icon-size, ($alert-icon-size * 16)); }

.wind-alert {
  @include icon($alert-icon-size, ($alert-icon-size * 23)+1); }

.extreme-temp-alert {
  @include icon($alert-icon-size, ($alert-icon-size * 29)+1); }

.snow-alert {
  @include icon($alert-icon-size, ($alert-icon-size * 18)); }

.ice-rain-alert {
  @include icon($alert-icon-size, ($alert-icon-size * 15)); }

/* Weather icons */

.water-icon {
  @include icon($weather-icon-size, ($weather-icon-size * 24));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 24)); } }

.clear-day {
  @include icon($weather-icon-size, ($weather-icon-size * 21));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 21)); } }

.clear-night {
  @include icon($weather-icon-size, ($weather-icon-size * 20));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 20)); } }

.rain {
  @include icon($weather-icon-size, ($weather-icon-size * 13));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 13)); } }

.snow, .sleet {
  @include icon($weather-icon-size, ($weather-icon-size * 18));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 18)); } }

.wind {
  @include icon($weather-icon-size, ($weather-icon-size * 23));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 23)); } }

.fog {
  @include icon($weather-icon-size, ($weather-icon-size * 14));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 14)); } }

.cloudy {
  @include icon($weather-icon-size, ($weather-icon-size * 12));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 12)); } }

.partly-cloudy-day {
  @include icon($weather-icon-size, ($weather-icon-size * 19));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 19)); } }

.partly-cloudy-night {
  @include icon($weather-icon-size, ($weather-icon-size * 17));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 17)); } }

.hail {
  @include icon($weather-icon-size, ($weather-icon-size * 15));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 15)); } }

.thunderstorm {
  @include icon($weather-icon-size, ($weather-icon-size * 16));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 16)); } }

.tornado {
  @include icon($weather-icon-size, ($weather-icon-size * 22));
  @include breakpoint("<small-phone") {
    @include icon($weather-icon-size / 2, (($weather-icon-size / 2) * 22)); } }

/* Settings icons and sprites */

.remove-icon {
  @include icon($mini-icon-size, ($mini-icon-size * 10)); }

.add-icon {
  @include icon($nav-icon-size, ($nav-icon-size * 10));
  transform: rotateZ(45deg); }

.telegram-sprite {
  @include sprite($map-sprite-size, ($map-sprite-size * 25)-1); }

.big-user-sprite {
  @include icon($large-sprite-size, ($large-sprite-size * 26)-6.1); }

/* Nightshift icons */

.day-icon {
  @include icon($mini-icon-size, ($mini-icon-size * 21)); }

.night-icon {
  @include icon($mini-icon-size, ($mini-icon-size * 20)); }
